import { Box } from '@mui/material';
import React from 'react';

export function AuthFormWrapper(props) {
  return (
    <Box
      sx={{
        height: '100%',
        backgroundColor: '#ffffff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          width: 300,
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Box
          component="img"
          sx={{ marginBottom: 11, px: { xs: 10, md: 8 } }}
          alt="Logo"
          src="/static/oriox_logo_blue.svg"
        />

        {props.children}
      </Box>
    </Box>
  );
}
