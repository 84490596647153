import React from 'react';
import { VALIDATION_ERRORS } from '../constants';
import { EMAIL_REGEXP } from '../regexp';

const validateEmail = value => {
  if (!value.length) return VALIDATION_ERRORS.FIELD_IS_REQUIRED;
  if (!EMAIL_REGEXP.test(value)) return VALIDATION_ERRORS.INVALID_EMAIL;
  return null;
};

const validatePassword = value => {
  if (!value.length) return VALIDATION_ERRORS.FIELD_IS_REQUIRED;
  return null;
};

const validateField = (name, value) =>
  name === 'email' ? validateEmail(value) : validatePassword(value);

export { validateField, validateEmail, validatePassword };
